<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="search"
        clearable
        append-icon="mdi-magnify"
        :label="$t('SEARCH')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :items-per-page="20"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: rowsPerPageItems,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        itemsPerPageText: $t('ITEMS_PER_PAGE_TEXT')
      }"
    >
      <template v-if="editableLots" v-slot:item.actions="{ item }">
        <v-btn color="primary" @click="handleOpenItemDialog(item)">
          {{ $t("OPEN_LOTS") }}
        </v-btn>
      </template>
      <template v-slot:item.name="{ item }">
        <strong>{{ item.name }}</strong>
      </template>
      <template v-slot:item.itemTypeName="{ item }">
        <small>{{ item.itemTypeName | capitalize }} </small>
      </template>
      <template v-slot:item.warehouseName="{ item }">
        <small>
          {{ item.warehouseName }}
        </small>
      </template>

      <template v-slot:item.audit.currentStock="{ item }">
        {{ item.audit.currentStock }} ({{ item.unitShortName }})
      </template>
      <template slot="body.append">
        <tr class="previewTr">
          <th colspan="100%">
            <div class="row">
              <div class="col-sm-12 col-md">
                <span
                  v-for="(itemType, index) in itemTypesCount"
                  v-bind:key="'itemType-' + index"
                >
                  {{ index | capitalize }}: {{ itemType }},
                </span>
                Total: {{ _.size(tempItems) }}
              </div>
              <div class="col-sm-12 col-md text-right">
                {{ $t("AUDIT_FOOD_COST") }}:
                {{ sumTotalFoodCost }}
              </div>
            </div>
          </th>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="itemDialog" max-width="900px" :retain-focus="false">
      <StockAuditItemLots
        :selectedItemLots.sync="selectedItemLots"
        :storeId="storeId"
        :stockAuditId="stockAuditId"
        @close="handleItemDialogClose"
        @save="handleSaveStockAuditLots"
        @addNewLot="addNewLot"
      />
    </v-dialog>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
import _ from "lodash";

import StockAuditItemLots from "@/modules/stock/components/audits/StockAuditItemLots.vue";
export default {
  name: "StockAuditItemsPreviewTable",
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    storeId: {
      type: Number,
      default: 0
    },
    stockAuditId: {
      type: Number,
      default: 0
    },
    editableLots: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: "",
      rowsPerPageItems: [-1, 100, 20, 10],
      selectedItemLots: {},
      itemDialog: false,
      headers: [
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        },
        {
          text: this.$t("ITEM_TYPE"),
          value: "itemTypeName",
          sortable: true
        },
        {
          text: this.$t("ITEM_WAREHOUSE"),
          value: "warehouseName",
          sortable: true
        },
        {
          text: this.$t("LAST_STOCK"),
          value: "audit.lastStock",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("CHANGED_STOCK"),
          value: "audit.changedStock",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("INITIAL_STOCK"),
          value: "audit.initialStock",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("ORDERED_UNIT_VALUE"),
          value: "audit.orderedUnitValue",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("CONSUMED_UNIT_VALUE"),
          value: "audit.consumedUnitValue",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("IDEAL_STOCK"),
          value: "audit.idealStock",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("CURRENT_STOCK"),
          value: "audit.currentStock",
          width: "180px",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("CONSUMPTION"),
          value: "audit.consumption",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("STOCK_DIFFERENCE"),
          value: "audit.stockDifference",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("AUDIT_FOOD_COST"),
          value: "audit.foodCost",
          sortable: true,
          align: "right"
        },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          align: "right"
        }
      ]
    };
  },
  components: { StockAuditItemLots },
  beforeMount() {},
  mounted() {},
  computed: {
    tempItems: {
      get: function() {
        return this.items;
      },
      set: function(newValue) {
        this.$emit("update:items", newValue);
      }
    },
    itemTypesCount() {
      let vm = this;
      var result = _.countBy(vm.tempItems, "itemTypeName");
      return result;
    },
    sumTotalFoodCost() {
      let vm = this;
      let totalFoodCost = _.sumBy(vm.tempItems, function(item) {
        return parseFloat(item.audit.foodCost);
      });
      return this.$options.filters.formatBalance(totalFoodCost);
    }
  },
  watch: {},
  methods: {
    handleOpenItemDialog(item) {
      let vm = this;
      vm.selectedItemLots = {
        id: item.id,
        name: item.name,
        lots: item.lots,
        currentStock: item.audit.currentStock,
        unitShortName: item.unitShortName
      };
      this.itemDialog = true;
    },
    handleSaveStockAuditLots(data) {
      let vm = this;
      //Update vm.items where id is selectedItemLots.id lots to be data
      let index = _.findIndex(vm.tempItems, function(o) {
        return o.id == vm.selectedItemLots.id;
      });
      vm.tempItems[index].lots = data;
    },
    handleItemDialogClose() {
      this.selectedItemLots = {};
      this.itemDialog = false;
    }
  }
};
</script>
