var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-title',[_c('v-text-field',{attrs:{"clearable":"","append-icon":"mdi-magnify","label":_vm.$t('SEARCH'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":20,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: _vm.rowsPerPageItems,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      itemsPerPageText: _vm.$t('ITEMS_PER_PAGE_TEXT')
    }},scopedSlots:_vm._u([(_vm.editableLots)?{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleOpenItemDialog(item)}}},[_vm._v(" "+_vm._s(_vm.$t("OPEN_LOTS"))+" ")])]}}:null,{key:"item.name",fn:function({ item }){return [_c('strong',[_vm._v(_vm._s(item.name))])]}},{key:"item.itemTypeName",fn:function({ item }){return [_c('small',[_vm._v(_vm._s(_vm._f("capitalize")(item.itemTypeName))+" ")])]}},{key:"item.warehouseName",fn:function({ item }){return [_c('small',[_vm._v(" "+_vm._s(item.warehouseName)+" ")])]}},{key:"item.audit.currentStock",fn:function({ item }){return [_vm._v(" "+_vm._s(item.audit.currentStock)+" ("+_vm._s(item.unitShortName)+") ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"previewTr"},[_c('th',{attrs:{"colspan":"100%"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md"},[_vm._l((_vm.itemTypesCount),function(itemType,index){return _c('span',{key:'itemType-' + index},[_vm._v(" "+_vm._s(_vm._f("capitalize")(index))+": "+_vm._s(itemType)+", ")])}),_vm._v(" Total: "+_vm._s(_vm._.size(_vm.tempItems))+" ")],2),_c('div',{staticClass:"col-sm-12 col-md text-right"},[_vm._v(" "+_vm._s(_vm.$t("AUDIT_FOOD_COST"))+": "+_vm._s(_vm.sumTotalFoodCost)+" ")])])])])])],2),_c('v-dialog',{attrs:{"max-width":"900px","retain-focus":false},model:{value:(_vm.itemDialog),callback:function ($$v) {_vm.itemDialog=$$v},expression:"itemDialog"}},[_c('StockAuditItemLots',{attrs:{"selectedItemLots":_vm.selectedItemLots,"storeId":_vm.storeId,"stockAuditId":_vm.stockAuditId},on:{"update:selectedItemLots":function($event){_vm.selectedItemLots=$event},"update:selected-item-lots":function($event){_vm.selectedItemLots=$event},"close":_vm.handleItemDialogClose,"save":_vm.handleSaveStockAuditLots,"addNewLot":_vm.addNewLot}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }