<template>
  <v-card>
    <v-card-title>
      {{ tempSelectedItemLots.name }}: {{ tempSelectedItemLots.currentStock }}
      {{ tempSelectedItemLots.unitShortName }}
    </v-card-title>
    <v-card-text>
      <div
        class="row"
        v-for="(lot, index) in tempSelectedItemLots.lots"
        v-bind:key="lot.stockLotId"
      >
        <div class="col">
          <v-text-field
            :disabled="!lot.custom"
            v-model="lot.stockLot"
            :label="$t('LOT')"
            hide-details
            type="text"
          ></v-text-field>
        </div>
        <div class="col">
          <FormDatepickerBase
            :disabled="!lot.custom"
            :type="'text'"
            :i18n="{
              label: 'LOT_EXP_DATE',
              placeholder: $t('LOT_EXP_DATE')
            }"
            :date.sync="lot.stockLotExpDate"
          ></FormDatepickerBase>
        </div>
        <div class="col">
          <v-text-field
            v-model="lot.remainingStock"
            :label="$t('UNIT_VALUE')"
            hide-details
            type="number"
            step="0.01"
            min="0"
            hide-spin-buttons
            @focus="$event.target.select()"
            @blur="filterUnitValue(lot)"
          >
            <template v-if="lot.custom" #append-outer>
              <v-icon @click="deleteLot(index)">mdi-delete</v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <v-btn @click="addNewLot">{{ $t("ADD_NEW") }}</v-btn>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="saveDisabled"
        color="red darken-1"
        text
        @click="handleItemDialogClose"
      >
        {{ $t("CLOSE") }}
      </v-btn>
      <v-btn
        :disabled="saveDisabled"
        color="blue darken-1"
        text
        @click="onSave"
      >
        {{ $t("SAVE") }}
      </v-btn>
      <div v-if="totalMissingStock != 0">
        {{ $t("DISCREPANCY_IN_QUANTITY") }}: {{ totalMissingStock }}
      </div>
    </v-card-actions>
  </v-card>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
import { mapGetters, mapState } from "vuex";

import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import { UPDATE_STOCK_AUDIT_LOTS } from "@/modules/stock/store/stock.module";

import _ from "lodash";
export default {
  name: "StockAuditItemLots",
  inheritAttrs: false,
  props: {
    selectedItemLots: {
      type: Object,
      default: function() {
        return {};
      }
    },
    storeId: {
      type: Number,
      default: 0
    },
    stockAuditId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  components: { FormDatepickerBase },
  beforeMount() {},
  mounted() {},

  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempSelectedItemLots: {
      get: function() {
        return this.selectedItemLots;
      },
      set: function(newValue) {
        this.$emit("update:selectedItemLots", newValue);
      }
    },
    totalRemainingStock() {
      if (_.size(this.tempSelectedItemLots.lots) == 0) {
        return 0;
      }
      const total = this.tempSelectedItemLots.lots.reduce((total, lot) => {
        return parseFloat(total) + parseFloat(lot.remainingStock);
      }, 0);
      return parseFloat(total.toFixed(6)); // Adjust the number of decimal places as needed
    },
    totalMissingStock() {
      // return (
      //   parseFloat(this.tempSelectedItemLots.currentStock) -
      //   parseFloat(this.totalRemainingStock)
      // );
      // Epsilon
      const computedResult =
        parseFloat(this.tempSelectedItemLots.currentStock) -
        parseFloat(this.totalRemainingStock);
      // Round the computed result to 2 decimal places
      const roundedResult = parseFloat(computedResult.toFixed(6));
      // Or use a tolerance value approach
      const tolerance = 0.000001; // Adjust this value based on your precision requirements
      if (Math.abs(computedResult) < tolerance) {
        return 0;
      } else {
        return roundedResult;
      }
    },
    saveDisabled() {
      return (
        parseFloat(this.totalRemainingStock) !=
        parseFloat(this.tempSelectedItemLots.currentStock)
      );
    }
  },
  watch: {},
  methods: {
    handleItemDialogClose() {
      this.$emit("close");
    },
    onSave() {
      let vm = this;
      let payload = {
        itemId: vm.tempSelectedItemLots.id,
        storeId: vm.storeId,
        lots: vm.tempSelectedItemLots.lots
      };
      this.$store
        .dispatch(UPDATE_STOCK_AUDIT_LOTS, {
          id: vm.stockAuditId,
          payload: payload
        })
        .then(data => {
          vm.errors = data.data.errors;
          vm.$emit("save", data.data);
          vm.$set(vm.tempSelectedItemLots, "lots", data.data);
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    addNewLot() {
      let vm = this;
      vm.tempSelectedItemLots.lots.push({
        id: null,
        stockLotId: null,
        stockLot: "",
        stockLotExpDate: "",
        remainingStock: 0,
        unitNumberType: vm.selectedItemLots.unitNumberType,
        custom: true
      });
    },
    deleteLot(index) {
      let vm = this;
      vm.tempSelectedItemLots.lots.splice(index, 1);
    },
    filterUnitValue(lot) {
      let vm = this;
      if (lot.remainingStock < 0) {
        lot.remainingStock = 0;
      }

      if (vm.totalMissingStock < 0) {
        const filteredUnitValue =
          parseFloat(lot.remainingStock) + parseFloat(vm.totalMissingStock);
        lot.remainingStock = parseFloat(filteredUnitValue.toFixed(6));
      }
    }
  }
};
</script>
